import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { syncCollection } from '/lib/sync-form';
import Select from '/ui/atoms/Select';
import TranslateItem from './TranslateItem';
import SearchTranslate from './SearchTranslate';
import { options } from './languageList';

import { Link } from 'react-router';

import {
  searchTranslateContext,
  addTranslateContext,
  deleteTranslateContext,
} from './store';

@connect(
	(state, props)=> {
		return {
			syncedEntity: 'translates',
			syncedId: props.postId,
      searched: state.translateContext.searched || [],
      userId: state.user.userId,
		}
	},
	(dispatch, ownProps) => {
		return {
      dispatchNewLanguage: (language) => {
        dispatch(change(`sync-form-post-${ownProps.postId}`, 'language', language));
      }, 
      searchRelatedTranslates: (prompt) => {
        dispatch(searchTranslateContext(prompt));
      },
      bindAnotherPostAsTranslate: (postOption) => {
        const anotherPostId = postOption.value;
        dispatch(addTranslateContext(ownProps.postId, anotherPostId));
      },
      deleteTranslateBinding: (anotherPost) => {
        dispatch(deleteTranslateContext(ownProps.postId, anotherPost));
      },
			dispatch,
		}
	}
)
@syncCollection()
export default class TranslateCollection extends PureComponent {
  constructor(props) {
    super(props)

    this.searchField = null;
  }

  render() {
    const {
      formValues,
      listClassName,
      disabled,
    } = this.props;

    const list = [];
    for (const p in formValues) {
      list.push(formValues[p]);
    }

    return (
      <>
        <strong>Язык и переводы</strong>
        <div className="ui segment" style={{display:'flex',flexDirection:'column',rowGap:'15px'}}>
          <div class="ui grid">
            <div class="six wide column" style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
              Язык материала
            </div>
            <div class="ten wide column">
              {disabled ? (options[this.props.language])
                : (<Select
                  name="language"
                  onChange={this.props.dispatchNewLanguage}
                  value={this.props.language}
                  options={options}
                />)
              }
            </div>
          </div>

          {
            !disabled && (<SearchTranslate
              searchFunction={this.props.searchRelatedTranslates}
              searchedItems={this.props.searched}
              onConfirm={this.props.bindAnotherPostAsTranslate}
            />)
          }
          {
            list.map((translatePost) => {
              if (!translatePost) {
                return null;
              }

              if (translatePost.Urls?.length) {
                translatePost.url = translatePost.Urls[0]?.canonical
              }

              return (
                <TranslateItem
                  translatePost={translatePost}
                  onDelete={this.props.deleteTranslateBinding}
                  disabled={disabled}
                />
              );
            })
          }
          {
            !disabled && (<button className='ui black basic button'
              onClick={() => this.props.add()}
            >
              <i className='plus circle icon'></i>
              Создать перевод
            </button>
          )
          }
        </div>
      </>
    )
  }
}
