export const SEARCH_RELATED_TRANSLATE_CONTEXT = 'SEARCH_RELATED_TRANSLATE_CONTEXT';
export const SEARCH_RELATED_TRANSLATE_CONTEXT_SUCCESS = 'SEARCH_RELATED_TRANSLATE_CONTEXT_SUCCESS';
export const SEARCH_RELATED_TRANSLATE_CONTEXT_FAILURE = 'SEARCH_RELATED_TRANSLATE_CONTEXT_FAILURE';

export const ADD_RELATED_TRANSLATE_CONTEXT = 'ADD_RELATED_TRANSLATE_CONTEXT';
export const ADD_RELATED_TRANSLATE_CONTEXT_SUCCESS = 'ADD_RELATED_TRANSLATE_CONTEXT_SUCCESS';
export const ADD_RELATED_TRANSLATE_CONTEXT_FAILURE = 'ADD_RELATED_TRANSLATE_CONTEXT_FAILURE';

export const DELETE_RELATED_TRANSLATE_CONTEXT = 'DELETE_RELATED_TRANSLATE_CONTEXT';
export const DELETE_RELATED_TRANSLATE_CONTEXT_SUCCESS = 'DELETE_RELATED_TRANSLATE_CONTEXT_SUCCESS';
export const DELETE_RELATED_TRANSLATE_CONTEXT_FAILURE = 'DELETE_RELATED_TRANSLATE_CONTEXT_FAILURE';
