import React from 'react';
import styled from 'styled-components';
import {
  Donate,
} from './components';
import PullQuoteController from '../PullQuote/components/PullQuoteController';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';
import { REGION } from '/const/config';

const Image = styled.img`
  max-width: 100%;
`;

const images = {
  ru: '/assets/donate-banner/ru.png',
  by: '/assets/donate-banner/by.png',
  ca: '/assets/donate-banner/ca.png',
};

const DonateNode = {
  type: nodeTypes.DONATE,
  object: objectTypes.BLOCK,

  schema: {
    isVoid: true,
  },

  create({ nodes }) {
    return {
      type: this.type,
      object: this.object,
      nodes,
    };
  },

  htmlRender(attrs) {
    return (
      <Donate
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node, attributes, type } = attrs;
    return (
      <PullQuoteController
        {...attrs}
      >
        <Image src={images[REGION] || '/assets/donate-banner/default.png'} />
        <Donate
          attributes={attributes}
          node={node}
          type={type}
        >
        </Donate>
      </PullQuoteController>
    );
  },
};

export default DonateNode;
