/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router';

import config from '/const/config';
import { formatTitlePost } from '/lib/formatString';

import Feed from '/ui/organism/Feed';
import Flag from '/ui/atoms/CountryFlag';

export default ({ translatePost, onDelete }) => {
  if (!translatePost) {
    return null;
  }

  const {
    id,
    uuid,
    url,
    published,
    disabled,
    language,
    CurrentVersion,
    deleted,
  } = translatePost;

  return (
    <div
      key={id}
    >
      <Feed.Row paddingBottom="0">
        <Feed.Cell width={2}>
          <Flag country={language} />
        </Feed.Cell>
        <Feed.Cell width={10}>
          <Link
            to={`/post/${id}`}
            className="post-item"
            style={{ textDecoration: deleted ? 'line-through' : 'none' }}
          >
            { formatTitlePost(CurrentVersion) }
          </Link>
        </Feed.Cell>
        <Feed.Cell width={4}>
          {deleted && (
            <span>
              <i className="icon ban" />
              Статья удалена
            </span>
          )}
          {!published && !deleted && (
            <a
              href={`${config.publicUrl}/preview/${uuid}`}
              target="_blank"
              rel="noreferrer"
            >
              Черновик
            </a>
          )}
          {published && !deleted && (
            <div className="item viewPublicPost">
              <a
                href={`${config.publicUrl}${url || ''}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon world" />
                На сайте
              </a>
            </div>
          )}
        </Feed.Cell>
        {!disabled && (
          <Feed.Cell
            width={2}
            title="Отвязать перевод"
            style={{
              justifyContent: 'center',
              cursor: 'pointer',
              userSelect: 'none',
              textDecoration: 'none',
            }}
            onClick={() => {
              if (!window.confirm('Материалы больше не являются переводами друг друга? Подтвердите удаление связки материлов. Это действие не заторонет содержание самих материалов.')) {
                console.info('Удаление материала отменено по требованию пользователя');
                return;
              }
              console.info('Удаление материала подтверждено пользователем');
              onDelete(id);
            }}
          >
            <i className="unlinkify icon" />
          </Feed.Cell>
        )}
      </Feed.Row>
    </div>
  );
};
