/* eslint-disable import/no-named-as-default-member */
import * as A from './actionTypes';

// SEARCH TRANSLATE
export const searchTranslateContext = (prompt) => ({
  type: A.SEARCH_RELATED_TRANSLATE_CONTEXT,
  payload: { prompt },
  meta: { remote: true },
});

export const searchTranslateContextSuccess = (posts) => ({
  type: A.SEARCH_RELATED_TRANSLATE_CONTEXT_SUCCESS,
  payload: { posts },
});

export const searchTranslateContextFailure = (error) => ({
  type: A.SEARCH_RELATED_TRANSLATE_CONTEXT_FAILURE,
  error,
});

// ADD TRANSLATE
export const addTranslateContext = (currentPostId, anotherPostId) => ({
  type: A.ADD_RELATED_TRANSLATE_CONTEXT,
  payload: { currentPostId, anotherPostId },
  meta: { remote: true },
});

export const addTranslateContextSuccess = (message) => ({
  type: A.ADD_RELATED_TRANSLATE_CONTEXT_SUCCESS,
  payload: { message },
});

export const addTranslateContextFailure = (error) => ({
  type: A.ADD_RELATED_TRANSLATE_CONTEXT_FAILURE,
  error,
});

// DELETE TRANSLATE
export const deleteTranslateContext = (post1Id, post2Id) => ({
  type: A.DELETE_RELATED_TRANSLATE_CONTEXT,
  payload: { post1Id, post2Id },
  meta: { remote: true },
});

export const deleteTranslateContextSuccess = (message) => ({
  type: A.DELETE_RELATED_TRANSLATE_CONTEXT_SUCCESS,
  payload: { message },
});

export const deleteTranslateContextFailure = (error) => ({
  type: A.DELETE_RELATED_TRANSLATE_CONTEXT_FAILURE,
  error,
});
