import React from 'react';
import {connect} from 'react-redux';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import { reduxForm, change, formValueSelector } from 'redux-form';
import "./Editor.styl"
import "./ActionMenu.styl";
import { REGION } from '../../const/config';
import {
	changeSnapshot,
	deletePost,
	restorePost,
	publishPost,
	setCurrentVersion
} from '../../actions/actions.js';
import config from '../../const/config';
import { confirmExtr } from '/utils/confirmExtr';
import { getTimezone } from '/utils/getTimezone';

const actionMenueClasses = {
	'ca': 'actionMenuBgCa',
	'by': 'actionMenuBgBy',
}
const actionMenuBg = actionMenueClasses[REGION] || actionMenuBg;

@connect(
	(state, ownProps) => {
		return {
			...ownProps.values,
			ui: state.ui,
			online_users: state.online_users,
			is_valid_slug: state.post.is_valid_slug,
			user: state.user.user,
		}
	},
	(dispatch, ownProps) => {
		return ({
			dispatch: dispatch,
			changeSnapshot: function({post_id, snapshot_id}) {
				dispatch(changeSnapshot({post_id, snapshot_id}));
			},
			deletePost: () => {
				if (ownProps.values && ownProps.values.id) {
					dispatch(deletePost({ post_id: ownProps.values.id }));
				} else {
					console.error('ActionMenu dont have delete function on case when post_id is not defined in values');
				}
			},
			restorePost: function({post_id}){
				dispatch(restorePost({post_id}));
			},
			publishPost: function({post_id, values}){
				if(confirmExtr(values)) {
					dispatch(publishPost({post_id, values, region: REGION}));
				} else {
					window.alert('Изменения не будут опубликованы, так как вы не приняли предупреждение об экстремистской организации')
				}
			},
			setCurrentSnapshot: function ({post_id, snapshot_id}) {
				dispatch(setCurrentVersion({post_id, snapshot_id}))
			}
		})
	}
)

export default class ActionMenu extends React.Component {
	constructor(props){
		super(props);
		this.changeSnapshot = this.changeSnapshot.bind( this );
		this.restorePost = this.restorePost.bind( this );
		this.publishPost = this.publishPost.bind( this );
		this.archivePost = this.archivePost.bind( this );
	}
	componentDidMount() {
		$('.showPostByLink').dropdown();
		$('.RevisionsDropdown').dropdown({
			action: function() {
				$('.ActionMenu .dropdown').dropdown('hide');
			},
			onChange: function(value, text, $selectedItem) {
			}
		});
		$('.ActionMenu').sticky({context:"#layout", offset:56, observeChanges: true});
		$('#ckeditor_toolbar').sticky({context:"#layout", offset:54, observeChanges: true});
	}
	changeSnapshot(snapshot_id) {
		//switch versions
		this.props.changeSnapshot({
			post_id: this.props.id,
			snapshot_id
		});
	}
	setCurrentSnapshot(){
		//save version
		this.props.setCurrentSnapshot({
              post_id: this.props.id,
              snapshot_id: this.props.current_version
        });
	}
	restorePost(){
		this.props.dispatch(change(this.props.form, 'deleted', false));
	}
	archivePost(){
		this.props.dispatch(change(this.props.form, 'published_version', null));
	}
	publishPost(){
		this.props.publishPost({post_id: this.props.id, values: this.props.values});
	}

	render() {
    const stickyStyle = {
      visibility: this.props.ui.ckToolbar ? 'visible' : 'hidden',
    };

		const {
			snapshots,
			ui,
			current_version,
			deleted,
			published_version,
			is_published,
			urls,
			online_users,
			valid,
			canBePublished,
			isEditDisallow,
		} = this.props;

		const { isChanged } = ui;

		var curIndexSnapshot = 0;

		const showRightMenu = 	!deleted &&
								canBePublished;

		const publishButton = (
			<div className="right menu">
				{ showRightMenu ?
					<div className="publishPost warning link item"
						 onClick={this.publishPost}>
						 {"Опубликовать"}
					</div>
				 	: null
		 		}
			</div>
		);

		const editDisallowMessage = (
			<div style={{color:'white', margin:'auto'}}>
				Вы не можете редактировать и публиковать материалы старше двух недель
			</div>
		);

		const revisionsMenuItems = snapshots && snapshots.map((item, i) => {
			let publishedClass;
			let activeClass = '';
			let user = item.User;
			if (item.id == current_version){
				activeClass = "active selected";
				curIndexSnapshot = i;
			}
			if (item.id == published_version){
				publishedClass = 'two wide column publishedRevision';
			}
			else
				publishedClass = 'two wide column';
			return (<div className={"item chooseRevision "+ activeClass} key={ item.id } data-snapshot={item.id} onClick={this.changeSnapshot.bind(this, item.id)}>
				<div className="ui two column grid">
					<div className={publishedClass}>№{i+1}</div>
					<div className="seven wide column">
						{new Date().toLocaleString(ruLocale, {timeZone: getTimezone()})}
					</div>
					<div className="seven wide column">{user? user.name: ''}</div>
				</div>
			</div>);
		});


		var revisionsData = ( snapshots && snapshots.length > 0 ) ? '' +
		'Версия № '+ (curIndexSnapshot+1) :
			'Новая версия';
		var revisionsmenu = null;
		if (snapshots && snapshots.length>0){
			revisionsmenu = (<div className="revisionsMenu menu" >
				<div className={"icon item saveRevision active"}
				     onClick={()=>{this.setCurrentSnapshot()}}>

					<i className="save icon"></i>
					Зафиксировать версию

				</div>
				<div className="divider"></div>
				{revisionsMenuItems}
			</div>);
		}
		var cls = "RevisionsDropdown ui dropdown item " + ((isChanged)? 'warning': 'positive');
		var versions = (<div className={cls}>{revisionsData}
			<i className="small dropdown icon"><div className="menu"></div></i>
			{revisionsmenu}
		</div>);

		const postPreviewRoute = this.props.type === 'podcast' ? '/preview/podcast/' : '/preview/';
		var showPost = (
			<div className="showPostByLink ui icon link dropdown item">Посмотреть
				<i className="small dropdown icon"><div className="menu"></div></i>
				<div className="menu">
					<a className="item viewPost" target="_blank" href={ `${config.publicUrl}${postPreviewRoute}${this.props.uuid}`}>
						<i className="icon browser"></i>
						<span>
							Превью
						</span>
					</a>
					{(published_version!==null && urls && urls.length>0)
						? (	<a className="item viewPublicPost" target="_blank"
						          href={ config.publicUrl + urls[urls.length-1].canonical }>
								<i className="icon world"></i>
								<span style={{ color: 'black' }}>
									На сайте
								</span>
							</a>)
						: null}
				</div>
			</div>
		);

		var hidePost = (
			<div className="ui showPostByLink scaryActions dark icon link dropdown item">
				<i className="big trash icon"></i>
				<i className="small dropdown icon"><div className="menu"></div></i>
				<div className="menu">
					{(this.props.published_version)?
						<div className="archivePost item" onClick={this.archivePost}>Снять с публикации</div>
						:
						null}
					<div className="archivePost item" onClick={this.props.deletePost}>Удалить публикацию</div>
				</div>
			</div>
		);

		let statusPublishedNav = null;
		let usersPanel = null;

		if (online_users && online_users.length > 0) {
			usersPanel = (
				<div className="item presentUsers">
					<div className="title">
						Сейчас здесь:&nbsp;
					</div>

					{/* Собственный пользователь админки как "Вы" */}
					{online_users
						.filter((user) => user.id === this.props.user.id)
						.map((user, index) => {
							return (
								<span
									className={ "user " + ( online_users.length > 1 ? "user_red" : "" ) }
									key={`user-yours-${index}`}
								>
									Вы
									{ online_users.length > 1 && (<span>&nbsp;и&nbsp;</span>) }
								</span>
							);
						})
					}

					{/* Остальные пользователи */}
					{online_users
						.filter((user) => user.id !== this.props.user.id)
						.map((user, index) => {
							const isSmbExceptMeHere = online_users.length > 1;
							return (
								<span 
									className="user user_red"
									key={`user-foreign-${index}`}
								>
									{user.name}&nbsp;
								</span>
							);
						})}
				</div>
			);
		}


		if (!deleted) {
			//через время надо убирать класс warning автоматически
			statusPublishedNav = (
				<div className={`ActionMenu ${actionMenuBg} ui borderless inverted menu`}>
					{
						!isEditDisallow
						&& versions
					}
					{showPost}
					{/* {this.props.user.is_editor && hidePost} */}
					{
						!isEditDisallow
						&& hidePost
					}
					{usersPanel}
					{
					isEditDisallow
						? editDisallowMessage
						: publishButton
					}
				</div>
			)
		} else {
			statusPublishedNav = (
				<div className="ActionMenu ui borderless inverted menu">
					<div className="text item">Публикация находится в архиве</div>
					<div className="unarchivePost positive link item" onClick={this.restorePost}>Восстановить</div>
				</div>
			)
		}


		return (
			<div className="ui sticky">
				{statusPublishedNav}
        <div id="ckeditor_toolbar" name="ckeditor_toolbar" className="ui sticky" style={stickyStyle}>
        </div>
			</div>
		)
	}
}
