import React, { Component } from 'react';
import styled from 'styled-components';
import {
  ModalCancel,
  ModalController,
  ModalSubmit,
} from '../../../../atoms';
import { REGION } from '/const/config';

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const images = {
  ru: '/assets/donate-banner/ru.png',
  by: '/assets/donate-banner/by.png',
  ca: '/assets/donate-banner/ca.png',
};

class DonateForm extends Component {
  constructor(props) {
    super(props);
    const { node } = props;
  }

  onSubmit = (event) => {
    const {
      onSubmit,
      onClose,
      type,
    } = this.props;
    event.persist();
    event.preventDefault();

    onSubmit({ type });
    onClose();
  };

  render() {
    const {
      onClose,
    } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <Image src={images[REGION] || '/assets/donate-banner/default.png'} />
        <ModalController>
          <ModalCancel onClick={onClose}>Отменить</ModalCancel>
          <ModalSubmit
            type="submit"
            value="Применить"
          />
        </ModalController>
      </form>
    );
  }
}

export default DonateForm;
