/* eslint-disable import/no-named-as-default-member */
import * as A from './actionTypes';

const initialState = {
  searched: [],
  searchPending: false,
  searchError: '',
  addContextPending: false,
  addContextError: '',
  deleteContextPending: false,
  deleteContextError: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Search posts for translate relation
    case A.SEARCH_RELATED_TRANSLATE_CONTEXT:
      return {
        ...state,
        searched: [],
        searchPending: true,
        searchError: '',
      };
    case A.SEARCH_RELATED_TRANSLATE_CONTEXT_SUCCESS:
      return {
        ...state,
        searched: action.payload.posts || [],
        searchPending: false,
        searchError: '',
      };
    case A.SEARCH_RELATED_TRANSLATE_CONTEXT_FAILURE:
      return {
        ...state,
        searched: [],
        searchPending: false,
        searchError: action.error,
      };

    // Add translate relation
    case A.ADD_RELATED_TRANSLATE_CONTEXT:
      return {
        ...state,
        addContextPending: true,
        addContextError: '',
      };
    case A.ADD_RELATED_TRANSLATE_CONTEXT_SUCCESS:
      return {
        ...state,
        addContextPending: false,
        addContextError: '',
      };
    case A.ADD_RELATED_TRANSLATE_CONTEXT_FAILURE:
      return {
        ...state,
        addContextPending: false,
        addContextError: action.error,
      };

    // Delete translate relation
    case A.DELETE_RELATED_TRANSLATE_CONTEXT:
      return {
        ...state,
        deleteContextPending: true,
        deleteContextError: '',
      };
    case A.DELETE_RELATED_TRANSLATE_CONTEXT_SUCCESS:
      return {
        ...state,
        deleteContextPending: false,
        deleteContextError: '',
      };
    case A.DELETE_RELATED_TRANSLATE_CONTEXT_FAILURE:
      return {
        ...state,
        deleteContextPending: false,
        deleteContextError: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
