import React, { useState } from 'react';
import Select, { components } from 'react-select';
import Flag from '/ui/atoms/CountryFlag';

// Компонент элемента списка.
// Показывает не только label из options,
// как дефолтный, но и страну и тип контента.
const CustomOption = (props) => {
  const { data, children } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <Flag country={data.language} />
      {' | '}
      <span>{data.type}</span>
      <p>
        <strong>{children}</strong>
      </p>
    </components.Option>
  );
};

// Компонент ввода для react-select.
// Часть хака, чтобы не скрывать содержимое инпута при потери фокуса.
const NonHidingInput = (props) => {
  const newProps = {
    ...props,
    isHidden: false,
  };
  return components.Input(newProps);
};

// React-select компонент с переопределённой логикой
// для поиска и выбора материалов, которые можно привязать к этому посту.
const CustomSelect = ({
  searchedItems,
  searchFunction,
  onConfirm,
}) => {
  // Map the searched contexts to options in the format react-select expects
  const options = searchedItems.map((item) => ({
    value: item.id,
    label: item.title,
    language: item.language,
    type: item.type,
  }));
  // State to hold the input value
  const [inputValue, setInputValue] = useState('');

  // Handle the selection change
  const handleSelectChange = (option) => {
    // You can perform additional actions here if needed
    onConfirm(option);
  };

  // Handle the input change (when typing in the search box)
  const handleInputChange = (val) => {
    if (!val) {
      if (inputValue.length <= 1) {
        setInputValue('');
        return;
      }
      return;
    }
    searchFunction(val); // Dispatch the action to search for the context
    setInputValue(val); // Update the inputValue state
  };

  return (
    <Select
      inputValue={inputValue}
      value={inputValue}
      onChange={handleSelectChange}
      onInputChange={handleInputChange}
      onFocus={() => handleInputChange(inputValue)}
      options={options}
      placeholder="Добавить другой материал как перевод"
      isClearable
      components={{
        Input: NonHidingInput,
        Option: CustomOption,
      }}
    />
  );
};

export default CustomSelect;
