import React, {Component, PureComponent} from 'react';
import { REGION } from '/const/config';
import {connect} from 'react-redux';
import { Field as ReduxFormField, FieldArray, reduxForm, change, formValueSelector, getFormValues, submit } from 'redux-form';
import { types } from '/utils/constants.js';
import formatInput from '/utils/formatInput';
import syncForm from '/lib/sync-form';
import { generateSlug } from '/lib/generate-slug';
import {validatePostForm, getPublishedPost} from '/actions/actions';
import syncInfo from '/../common/sync-channel';
import { isEqualState, allTransactionsApplied } from '/store/selectors';
import RelatedField from '/components/RelatedField/RelatedField.js'
import ThemesPost from "/components/RelatedField/ThemePost.js";
import RichText from '/components/RichText/RichText.js';
import AuthorsForm from '/components/AuthorsForm/AuthorsForm.js';
import Events from '/components/Events/EventsList.js';
import Test from '/components/Test/Test.js';
import WebPushButton from '/components/WebPushButton/WebPushButton.js';
import NoteForm from '/components/Notes/NoteForm';
import AddEvent from '/components/Events/AddEvent.js';
import AddSummary from '/components/Events/AddSummary.js';
import SlateRichText from "/components/SlateRichText/SlateRichText";
import SlateEditor from "/components/SlateRichText/Editor";
import ArchiveSource from '/components/ArchiveSource/ArchiveSource';
import Preview from '/components/Preview/Preview';
import Cards from '/components/Cards';
import PodcastLinks from '/components/PodcastLinks';
import ActionMenu from './ActionMenu';
import DatesPost from './DatesPost.js';
import TransformPostButton from './TransformPostButton';
import ToggleSlate from './ToggleSlate';
import ReduxFormInput from '../ReduxFormInput';
import {
	Label,
	Input,
	SocpicSelect,
	Warning,
} from '/ui/atoms';
import {
	Field
} from '/ui/molecules';
import { PartnerSelect } from '/features/Partners';
import './PostForm.styl';
import './CKEditorConfig.js';
import Languages from '/features/Languages';
import {
	SimpleRichText,
  } from '/ui/organism';
import SimpleRichTextSummary from './SimpleRichTextSummary';
import Illustration from '/features/Illustration';
import TypographButton from '../Features/TypographButton';
import SeoControls from './SeoControls';
import MobileAppSettings from '/features/MobileAppSettings';

import { timzoneShort } from '/utils/dateFormat';

const required = (value) => {
	return value ? undefined : 'Обязательное поле';
};

const TITLE_RECOMMEND_LEN = 140;
const TITLE_WARNING_LEN = 160;
const TITLE_MAX_LEN = 180;

const checkTitle = value => {
	let warning = '';

	if (value && value.length > TITLE_RECOMMEND_LEN) {
		warning = `Длина заголовка ${value.length} превышает рекомендуемую длину ${TITLE_RECOMMEND_LEN} на ${value.length - TITLE_RECOMMEND_LEN} символов!`;
	}

	if (value && value.length > TITLE_WARNING_LEN) {
		warning = `Длина заголовка ${value.length}. От ${TITLE_WARNING_LEN} до ${TITLE_MAX_LEN} рекомендуется уменьшить размер шрифта шеринговой`;
	}

	if (value && value.length > TITLE_MAX_LEN) {
		warning = `Длина заголовка ${value.length} превышает максимум из ${TITLE_MAX_LEN} символов. Материал не опубликуется`;
	}

	return warning;
}

const validate = (values, statePost) => {
	const errors = {}
	if (!statePost.is_valid_slug){
		errors.slug = "Неправильный урл";
	}
	if (statePost.is_exists_slug){
		errors.slug = "Урл уже существует";
	}
	if (!values || !values.title){
		errors.title = "Обязательное поле";
	}
	if (!values || !values.slug){
		errors.slug = "Обязательное поле";
	}
	if (values && values.title && values.title.length > TITLE_MAX_LEN) {
		errors.title = `Материал не может быть опубликован! Длина заголовка ${values.title.length} из ${TITLE_MAX_LEN} возможных символов`;
	}
	return errors;
}

const eventTypes = ['chronicle', 'online', 'podcast'];
const isEvents = type => eventTypes.includes(type);

const ReduxSimpleRichText = ({ input, placeholder }) => (
	<SimpleRichText
		value={input.value}
		onChange={input.onChange}
		placeholder={placeholder}
		style={{
			height: '6em',
			backgroundColor: 'white',
			overflow: 'hidden',
			border: 0,
		}}
	/>
);

@connect(
	(state, ownProps) => {
		const formName = syncInfo.formFunc('post', ownProps.id);
		const selector = formValueSelector(formName);
        const formValues = getFormValues(formName)(state);
		// const post_published = state.form[syncInfo.formFunc('published_post', ownProps.id)];
        // const valuesSelector = getFormValues(syncInfo.formFunc('post', ownProps.id));
		const errors = validate(formValues, state.post);
		const isTransactionsApplied = allTransactionsApplied(state);
		return {
			canBePublished: !isEqualState(state, ownProps)
				&& Object.keys(errors).length == 0
				&& isTransactionsApplied,
			isApplied: isTransactionsApplied,
      		validateErrors: errors,
			title: selector(state, 'title'),
			publish_date: selector(state, 'publish_date'),
			createdAt: selector(state, 'createdAt'),
			updatedAt: selector(state, 'updatedAt'),
			// created_post_date: selector(state, 'createdAt'),
			// updated_post_date: selector(state, 'updatedAt'),
			urls: selector(state, 'urls'),
			authors: selector(state, 'authors'),
			ui: state.ui,
			syncedEntity: 'post',
			syncedId: ownProps.id,
			snapshots: selector(state, 'snapshots'),
			current_version: selector(state, 'current_version'),
			published_version: selector(state, 'published_version'),
			deleted: selector(state, 'deleted'),
			type: selector(state, 'type'),
			modifier: selector(state, 'modifier'),
			publisher: selector(state, 'publisher'),
			creator: selector(state, 'creator'),
			is_completed: selector(state, 'is_completed'),
			questions: selector(state, 'questions'),
			results: selector(state, 'results'),
			answers: selector(state, 'answers'),
			is_published: selector(state, 'published_version') ? true: false,
			is_feature: selector(state, 'is_feature'),
			background_test_image: selector(state, 'background_test_image'),
			is_quotes: selector(state, 'is_quotes'),
			published_post: state.post.published_post,
			// cards: selector(state, 'cards')
			is_valid_slug: state.post.is_valid_slug,
			is_exists_slug: state.post.is_exists_slug,
			is_full_width: selector(state, 'is_full_width'),
			image: selector(state, 'image'),
			isSlateRichText: selector(state, 'is_slate_rich_text'),
	  		isOldLead: selector(state, 'is_old_lead'),
			is_hide_results: selector(state, 'is_hide_results'),
			contextTitle: selector(state, 'context_title'),
			backgroundColor: selector(state, 'background_color'),
			partner_description_on: selector(state, 'partner_description_on'),
			additionalImage: selector(state, 'additional_page_image'),
			partner_id: selector(state, 'partner_id'),
			language: selector(state, 'language'),
			socpic__fontSize: selector(state, 'socpic__fontSize'),
			seo_title: selector(state, 'seo_title'),
			seo_description: selector(state, 'seo_description'),
			summary: selector(state, 'summary'),
			podcast_links: selector(state, 'podcast_links'),
			viewCount: selector(state, 'view_count'),
			isEditDisallow: (selector(state, 'requireAdminRole') && !state.user.user.is_admin)
				|| selector(state, 'requireEditorRole') && !state.user.user.is_editor,
			uuid: selector(state, 'uuid'),
			language: selector(state, 'language'),
		}
    },
    (dispatch) => ({
		changePartner: (form, id) => { dispatch(change(form, 'partner_id', id)); },
		changePodcastLinks: (form, id) => { dispatch(change(form, 'podcast_links', id)); },
		changeSummary: (form, id) => { dispatch(change(form, 'summary', id)); },
		changeSocpicFontSize: (form, id) => { dispatch(change(form, 'socpic__fontSize', id)); },
		dispatch,
    })
)

@syncForm()
@reduxForm()
class PostForm extends PureComponent {
	constructor(props){
		super(props);
		this.state = {
			isDisabledSlug: true,
			isNewPost: true,
			isChangedSlug: false,
			isDifferentFromPublished: false,
			isReverse: false,
			isPreview: false,
			isSummaryShow: false,
			isSeoShow: false,
		};
		this.generateSlug = this.generateSlug.bind(this);

		this.dispatch = this.props.dispatch;
	}
	componentDidMount(){
		$('#ckeditor_toolbar').sticky({context:".ui.container", offset:56, jitter: 0});
        if (!window.CKEDITOR)
            setTimeout(this.checkLoadingEditor.bind(this), 50);
        else {
            //window.CKEDITOR.replace( 'ckeditor_toolbar' );
		}
	}

	componentWillReceiveProps(props){
		if (props.syncedId != this.props.syncedId)
			this.props.dispatch({type: 'POST_RESET'});
		if (props.syncedId != this.props.syncedId || props.published_version != this.props.published_version)
        	this.props.dispatch(getPublishedPost(this.props.syncedId));
		if (props.is_valid_slug!==this.props.is_valid_slug && !props.is_valid_slug){
            document.dispatchEvent(
				new CustomEvent('custom-notify', {
	                detail: {
	                    title: `Неправильный урл`,
	                    level: 'error',
	                    autoDismiss:0
	                }
	            })
            );
		}
		if (props.is_exists_slug!==this.props.is_exists_slug && props.is_exists_slug){
			document.dispatchEvent(
				new CustomEvent('custom-notify', {
					detail: {
						title: `Урл уже существует!`,
						level: 'error',
						autoDismiss:0
					}
				})
			);
		}
	}

    componentWillMount () {
        this.props.dispatch({type: 'POST_RESET'});
        this.props.dispatch(getPublishedPost(this.props.syncedId));
		}

	generateSlug(){
    const slug = generateSlug(this.props.title);
    this.props.dispatch(change(this.props.form, 'slug', slug));
	}

	eventsRef = React.createRef();


  handleReverse() {
    this.setState((state) => ({
      isReverse: !state.isReverse,
    }));
	}

	handleSummary() {
		if (this.state.isSummaryShow) {
			if (confirm('Точно удалить краткое содержание?')) {
				this.props.dispatch(change(this.props.form, 'summary', ''));
			}
		}

		this.setState((state) => ({
			isSummaryShow: !state.isSummaryShow,
		}));
	}

	handlePreview(previewMode) {
		this.setState(() => ({ isPreview: previewMode })
		);
	}

	renderLead(isOldLead) {
		const component = isOldLead ? RichText : SlateRichText;
		return isOldLead
			? (<Field>
				<Label>Лид</Label>
				<div className="ckeditor" name="snapshot.lead" autoComplete="off">
				<ReduxFormField
					name="lead"
					component={component}
					heigth="200"
					key="lead"
				/>
				</div>
			</Field>)
			: (<Field>
				<Label>Лид</Label>
				<ReduxFormField
					name="lead"
					component={component}
					heigth="200"
					key="lead"
					disabled={this.props.isEditDisallow}
				/>
			</Field>)
	}

	renderTextEditor(isSlateRichText) {
		return isSlateRichText
			? (<Field>
				<ReduxFormField name="body"
					component={SlateRichText}
					entityId={this.props.id}
					contextTitle={this.props.contextTitle}
					key="body"
					disabled={this.props.isEditDisallow}
				/>
			</Field>)
			: (<div className="ckeditor" name="body">
				<ReduxFormField
				  name="body"
				  component={RichText}
				  key="body"
				/>
			  </div>
			)
	}

	renderToogleSlateRichText({ display, checked }) {
		if (!display) return null;

		return (<Field>
			<div className="ui toggle checkbox">
				<ReduxFormField name="is_slate_rich_text"
					component={ToggleSlate}
					isSlateRichText={checked}
					key="is_slate_rich_text"
				/>
				<Label>Новый редактор</Label>
			</div>
		</Field>)
	}

	renderForm() {
		const {
			id,
			form,
			post,
			formCreators,
			handleSubmit,
			ui,
			postActions,
			type,
			formValues,
			validateErrors,
			published_post,
			isSlateRichText,
			isOldLead,
			is_published,
			contextTitle,
			partner_id,
			socpic__fontSize,
			seo_title,
			seo_description,
			summary,
			title
		} = this.props;
		const { isReverse } = this.state;
		const isBackgroundColor = type === 'podcast';

		this.warningBg = '#CEA049'; // default orange color of bg
		if (this.props.title && this.props.title.length > TITLE_MAX_LEN) {
			this.warningBg = '#CE5149'; // red color of bg
		}

		return (
			<div className="ui vertically padded grid">

				{/* ЛЕВАЯ КОЛОНКА РЕДАКТОРА НАЧИНАЕТСЯ ТУТ! */}
				<div className="ten wide column">
					<Label>Заголовок</Label>
					<Field
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<ReduxFormField
							name="title"
							type="text"
							format={formatInput}
							normalize={formatInput}
							debounceTime={1500}
							component={ReduxFormInput}
							label="Заголовок"
							warn = {checkTitle}
							disabled={this.props.isEditDisallow}
						/>
						{
							!this.props.isEditDisallow &&
							(<TypographButton
								value={this.props.title}
								onClick={(text) => {
									if (text && typeof text === 'string') {
										this.props.dispatch(change(this.props.form, 'title', text));
									} else {
										console.error('Error on typograph title. Typograph return not string');
									}
								}}
							/>)
						}
					</Field>
					{ validateErrors && validateErrors['title'] && <span className="ui negative message input_error">{validateErrors['title']}</span> }
					<Field>
						<Label>URL</Label>
						<ReduxFormField name="slug"
							id="slug"
							component={ReduxFormInput}
							debounceTime={1500}
							type="text"
                       		label="Урл"
                       		normalize={value => value.replace(/[^a-zA-Z0-9\/\-_]+/g, '')}
							disabled={this.props.isEditDisallow}
						/>
						{ !this.props.isEditDisallow && validateErrors && validateErrors['slug'] &&
							<span className="ui negative message input_error">{validateErrors['slug']}</span>
						}
						{
							!this.props.isEditDisallow &&
							(
								<span className="ui button btn_autogenerateurl" onClick={this.generateSlug}>
									Автоматически сгенерировать
								</span>
							)
						}
					</Field>

					{type == 'number'
						? <Field>
							<Label>Заголовок: число</Label>

							<ReduxFormField name="numberTitle"
								id="number_title"
								format={formatInput}
								normalize={formatInput}
								debounceTime={1500}
								component={ReduxFormInput}
								autoComplete="off"
								type="text"
								disabled={this.props.isEditDisallow}
							/>
						</Field>
						: null
					}

					{type == 'number'
						? <Field>
							<Label>Заголовок: источник</Label>
							<ReduxFormField name="sourceTitle"
								id="source_title"
								autoComplete="off"
								debounceTime={1500}
								component={ReduxFormInput}
								format={formatInput}
								normalize={formatInput}
								type="text"
								disabled={this.props.isEditDisallow}
							/>
						</Field>
						: null
					}

					<SeoControls
						seo_title={this.props.seo_title}
						seo_description={this.props.seo_description}
						isEditDisallow={this.props.isEditDisallow}
					/>

					{type !== "news"
						? (<Field>
							<Label>Анонс</Label>
							<ReduxFormField name="announce"
								id="announce"
								autoComplete="off"
								debounceTime={1500}
								component={ReduxFormInput}
								format={formatInput}
								normalize={formatInput}
								type="text"
								disabled={this.props.isEditDisallow}
							/>
						</Field>)
						: null
					}

					{/* 08.11.2024 установлено значение
						display: false - всегда прячем
						эту старую функцию выбора редактора
						для компактности
					*/}
					{!this.props.isEditDisallow && !isEvents(type)
						? this.renderToogleSlateRichText({ display: false, checked: isSlateRichText })
						: null
					}

					{ type !=="news"
						? this.renderLead((isOldLead || !isSlateRichText))
						: null
					}

					{(type == 'article' && !this.props.isEditDisallow)
						? (<Field>
								<div className="ui toggle checkbox">
									<ReduxFormField name="is_full_width"
										id="fullWidth"
										component="input"
										type="checkbox"
									/>
									<Label>Без правой колонки</Label>
								</div>
						</Field>)
						: null
					}

				{((type == 'article' && !this.props.isEditDisallow) && <AddSummary
				isSummaryShow={this.state.isSummaryShow || this.props?.summary?.length > 0}
				handleSummary={() => this.handleSummary()} />)}

				{((type == 'article' && (this.state.isSummaryShow)) || (type == 'article' && (this.props?.summary?.length > 0))) ?
					(<Field>
						<Label>Краткое содержание</Label>
						<ReduxFormField
							name="summary"
							component={SlateRichText}
							entityId={this.props.id}
							contextTitle={this.props.contextTitle}
							key="summary"
							reduced={true}
							toolbarOptions={{
								photo: false,
								embed: false,
								pullQuote: false,
								headingList: [
									SlateEditor.NODE.PARAGRAPH,
									SlateEditor.NODE.HEADING_TWO,
								]
							}}
						/>
					</Field>)
					: null
				}

				{!this.props.isEditDisallow && type !== 'news'
					? (<Field>
						<Label>Название формата</Label>
						<ReduxFormField name="custom_format"
							id="custom_format"
							autoComplete="off"
							component={ReduxFormInput}
							type="text"
						/>
					</Field>)
                  	: null
                }

				{(isEvents(type) || type == "card")
					? null
					: (
						<Field>
							<Label htmlFor="body" className="body_label">
								Текст
							</Label>
							<div>
								{ this.renderTextEditor(isSlateRichText) }
							</div>
						</Field>
					)
				}

				{type === 'test' &&
					<Test questions={this.props.questions}
						results={this.props.results}
						answers={this.props.answers}
						post_id={this.props.id}
						is_quotes={this.props.is_quotes}
						background_test_image={this.props.background_test_image}
						is_hide_results={this.props.is_hide_results}
						form={this.props.form}
					/>
				}

				{type == 'card' &&
					<Cards
						post_id={this.props.id}
						form = {form}
					/>
				}

				{!this.props.isEditDisallow && isEvents(type)
					? (<Field>
                    	<div className="ui horizontal basic label">
                      		<Field>
                        		<div className="ui toggle checkbox">
                          			<ReduxFormField name="is_completed" component="input" type="checkbox" />
                          			<Label>Трансляция завершена</Label>
                        		</div>
                      		</Field>
                    	</div>
                    	<div className="ui horizontal basic label">
                      		<Field>
                        		<div className="ui toggle checkbox">
                          			<Input name="is_reverse" type="checkbox" onChange={() => this.handleReverse()} />
                          			<Label>Перевернуть трансляцию</Label>
                        		</div>
                      		</Field>
                    	</div>
					</Field>)
					: null
				}

			{((!this.props.isEditDisallow && type == 'online' || type == 'translate') && <AddSummary
				isSummaryShow={this.state.isSummaryShow || this.props?.summary?.length > 0}
				handleSummary={() => this.handleSummary()} />)}

				{(((type == 'online' || type == 'translate') && (this.state.isSummaryShow)) || ((type == 'online' || type == 'translate') && (this.props?.summary?.length > 0))) ?
					(<Field>
						<Label>Краткое содержание</Label>
						<ReduxFormField
							name="summary"
							component={SlateRichText}
							entityId={this.props.id}
							contextTitle={this.props.contextTitle}
							key="summary"
							reduced={true}
							toolbarOptions={{
								photo: false,
								embed: false,
								pullQuote: false,
								headingList: [
									SlateEditor.NODE.PARAGRAPH,
									SlateEditor.NODE.HEADING_TWO,
								]
							}}
						/>
					</Field>)
					: null
				}

			{((type == 'online' || type == 'chronicle' || type == 'podcast') && <AddEvent
				handleAddEvent={() => { this.eventsRef.current.add() }} />)}

				{isEvents(type)
					? <Events
						addEventRef={ref => this.eventsRef = { current: ref }}
						isSlateRichText={isSlateRichText}
						is_reverse={isReverse}
						post_id={this.props.id}
						type={this.props.type}
						key={"events-"+this.props.id}/>
					: null
				}
			</div>
			{/* КОНЕЦ ЛЕВОЙ КОЛОНКИ РЕДАКТОРА */}

			{/* ПРАВАЯ КОЛОНКА РЕДАКТОРА НАЧИНАЕТСЯ ТУТ! */}
			<div className="six wide column">
				<Illustration
					type={type}
					form={form}
					image={this.props.image}
					isBackgroundColor={isBackgroundColor}
					tmpSocpic={formValues.tmp_socpic}
					richTextComponent={ReduxSimpleRichText}
					additionalImage={this.props.additionalImage}
					disabled={this.props.isEditDisallow}
				/>

				<Field>
					<ReduxFormField
						name="socpic__fontSize"
						id="socpic__fontSize"
						component={SocpicSelect}
						defaultValue={this.props.socpic__fontSize}
						placeholder="Размер шрифта в шеринговой"
						options={[
							{value: '56', label: 'Стандартный шрифт'},
							{value: '50', label: 'Шрифт поменьше'},
							{value: '46', label: 'Шрифт еще поменьше'}
						]}
						{...{
							onOptionSelect: (value) => this.props.changeSocpicFontSize(form, value)
						}}
						// onOptionSelect={(value) => this.props.changeSocpicFontSize(form, value)}
						onChange={(value) => this.props.changeSocpicFontSize(form, value)}
					/>
				</Field>
				{checkTitle(this.props.title).length > 0 &&
					<Warning bg={this.warningBg}>
						{checkTitle(this.props.title)}
					</Warning>
				}

				{(!this.props.isEditDisallow && this.props.urls && this.props.urls.length>0)
					? (
						<Field>
							<WebPushButton
								title={this.props.title}
								announce={this.props.announce}
								type={this.props.type}
								urls={this.props.urls}
							/>
						</Field>
					)
					: null}

				{!['news', 'online', 'chronicle'].includes(type) && (<AuthorsForm  post_id={this.props.id}/>)}

				<PodcastLinks
					type={type}
					links={this.props.podcast_links}
					onChange={(links) => this.props.changePodcastLinks(form, links)}
				/>
				<ThemesPost
					entity="themes"
					id={id}
					key={"themes"+id}
					filter={(list) => list.filter(el => !el.is_region_selector)}
					title='Темы'
					placeholder='Связать с темой...'
					disabled={this.props.isEditDisallow}
				/>
				<ThemesPost
					entity="themes"
					id={id}
					key={"themesRegions"+id}
					filter={(list) => list.filter(el => el.is_region_selector)}
					title='Регионы'
					placeholder='Связать с регионом...'
					disabled={this.props.isEditDisallow}
				/>

				{
					type === 'article' &&
						<Languages
							disabled={this.props.isEditDisallow}
							postId={this.props.id}
							language={this.props.language}
						/>
				}

				{!this.props.isEditDisallow && ['article', 'test'].includes(type) ?
					<Field>
						<Label>Партнер</Label>
						<ReduxFormField
							name="partner_id"
							id="partner_id"
							component={PartnerSelect}
							onOptionSelect={(value) => { this.props.changePartner(form, value); }}
							defaultValue={this.props.partner_id}
						/>
					</Field> : null
				}

				{partner_id && (
					<div className="ui segment">
						<div className="ui small dividing header">
							Настройки партнерского материала
						</div>
						<Field>
							<div className="ui toggle checkbox">
								<ReduxFormField
									name="partner_description_on"
									component="input"
									type="checkbox"
								/>
								<Label>Включить дисклеймер</Label>
							</div>
						</Field>
					</div>
				)}

				{(type !== "number" && type !== "news") &&
					<RelatedField
						entity="categories"
						id={this.props.id}
						key={"categories"+this.props.id}
						disabled={this.props.isEditDisallow}
					/>
				}

				{REGION === 'ru' &&
					<MobileAppSettings
						formName={form}
						disabled={this.props.isEditDisallow}
					/>
				}

				<Field>
					<Label>
						Дата публикации {`(${timzoneShort})`}
					</Label>
					<DatesPost
						{...this.props}
						post_id={this.props.id}
						disabled={this.props.isEditDisallow}
					/>
				</Field>

				<NoteForm
					id={this.props.id}
					disabled={this.props.isEditDisallow}
				/>
				<TransformPostButton
					id={this.props.id}
					typePost={this.props.type}
					disabled={this.props.isEditDisallow}
				/>
				<ArchiveSource
					id={this.props.id}
				/>
			</div>
			{/* КОНЕЦ ПРАВОЙ КОЛОНКИ РЕДАКТОРА */}
		</div>)
	}
	render(){
		const { id, image, type, formValues, validateErrors, additionalImage, canBePublished, isApplied, form, post, formCreators, handleSubmit, ui, postActions,  published_post } = this.props;
		const { isReverse, isPreview } = this.state;
		if (!formValues || !formValues.id) return null;
		return (
			<div id="EditorForm" className="ui">
				<ActionMenu values={formValues}
							form={this.props.form}
				            valid={validateErrors && Object.keys(validateErrors).length ==0 ? true : false }
				            canBePublished={this.props.canBePublished}
							isEditDisallow={this.props.isEditDisallow}
							uuid={this.props.uuid}
				/>
				<div className="ui container basic segment">
					<div className="ui grid">
						<div className="left floated left aligned twelve wide column">
							<div className="ui dividing large header">
								{ types[type] }
							</div>
						</div>
						<div className="right aligned four wide column">
							<div className="ui buttons">
								<button
										className={`ui ${isPreview ? '' : 'primary'} button`}
										onClick={ ()=>{this.handlePreview(false)} }
										>Редактировать</button>
								<button
									className={`ui ${isPreview ? 'primary' : ''} ${isApplied ? '' : 'disabled'} button`}
									onClick={ ()=>{this.handlePreview(true)} }
								>
									Просмотр
								</button>
							</div>
						</div>
					</div>
					<div className={`${isPreview ? "ui sixteen wide column"  : "form_hidden"}`}>
						<Preview
							id={id}
							uuid={this.props.uuid}
							special={type}
							isReady={isApplied && isPreview}
							entryImage={additionalImage || image}
							socpic={formValues.tmp_socpic}
						/>
					</div>
					<div className={`${isPreview ? 'form_hidden' : ''}`}>
						{this.renderForm()}
					</div>
				</div>
            </div>
		)
	}
}

export default PostForm;
