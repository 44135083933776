import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import syncForm from '../../lib/sync-form';
import syncInfo from '../../../common/sync-channel';
import generatePassword from '../../utils/generatePassword';
import {
    logout,
    changePassword,
    changePasswordReset,
    attachOTP,
    detachOTP,
    fetchUserSessionList,
    endAllActiveSessions,
} from '../../actions/actions.js';
import RoleTags from './RoleTags';
import UserSessionList from './UserSessionList';

const ErrorLabel = styled.div`
    color: red;
    font-size: 12px;
    line-height: 120%;
    margin: 8px 0;
`;

const ERROR_LABEL = 'Пароль должен содержать минимум 8 символов, включая как минимум одну строчную букву, одну заглавную букву, одну цифру и один специальный символ.';
@connect(
    (state, ownProps) => {

        const selector = formValueSelector(syncInfo.formFunc('user', ownProps.params.id));

        return {
            profile: state.userProfile,
            name: selector(state, 'name'),
            email: selector(state, 'email'),
            active: selector(state, 'active'),
            is_admin: selector(state, 'is_admin'),
            is_editor: selector(state, 'is_editor'),
            is_meta: selector(state, 'is_meta'),
            otp_enabled: selector(state, 'otp_enabled'),
            qr_code: selector(state, 'qr_code'),
            sessions: selector(state, 'sessions'),
            userId: state?.user?.userId,
            currentIdIsAdmin: state.user.user.is_admin,
            syncedEntity: 'user',
            syncedId: parseInt(ownProps.params.id, 10),
            changedPassword: state.changePassword.changed,
            sessions: state.userProfile.sessions,
            sessionsError: state.userProfile.sessionsError,
            sessionsLoading: state.userProfile.sessionsLoading,
        };
    },
    (dispatch, ownProps) => ({
        dispatch,
        getUserSessionList: () => {
            dispatch(fetchUserSessionList(ownProps.params.id));
        },
        logoutForeignUser: () => {
            dispatch(endAllActiveSessions(ownProps.params.id));
        }
    })
)
@syncForm()
@reduxForm()
export default class UserProfileForm extends React.Component {

    static propTypes = {
        params: PropTypes.object,

        resetForm: PropTypes.func,
        fetchUserProfile: PropTypes.func
    }

    constructor (props) {
        super(props);

        this.state = {
            pass: '',
            newPassword: '',
            passwordConfirm: '',
            showPassword: false,
            isStrongPassword: false,
            passError: ERROR_LABEL
        };

        this.genPass = this.genPass.bind(this);
    }
    genPass (e) {

        e.preventDefault();

        const newPass = generatePassword();

        if (confirm('Точно сбросить пароль?')){
            this.setState({
                pass: newPass
            })

            this.props.dispatch(change(this.props.form, 'password', newPass));
        }
    }
    logout() {
        this.props.dispatch(logout());
    }
    showChangePassword(){
        this.setState({
            showPassword: true
        });
    }
    componentDidMount() {
        this.props.getUserSessionList();
    }
    componentWillReceiveProps(props){
        if (props.userId != this.props.userId)
        {
            this.setState({
                pass: '',
                newPassword: '',
                passwordConfirm: '',
                newPasswordChange: true,
                showPassword: false,
                isStrongPassword: false,
                passError: ERROR_LABEL
            });
            this.props.dispatch(changePasswordReset());
        }
    }
    changeNewPassword(e){
        let strongPassExp = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')

        if (strongPassExp.test(e.target.value)) {
            this.setState({ isStrongPassword: true, passError: '' })
        }

        if (!(strongPassExp.test(e.target.value))) {
            this.setState({ isStrongPassword: false, passError: ERROR_LABEL});
        }
        
        this.setState({newPassword: e.target.value, newPasswordChange: true});
    }
    changeConfirmPassword(e){
        this.setState({passwordConfirm: e.target.value, newPasswordChange: false});

    }
    toggle2faEnable(e){
        const otpFn = this.props.otp_enabled ? detachOTP : attachOTP;
        this.props.dispatch(otpFn(this.props.userId));
    }
    sendChangePassword(e){
        e.preventDefault();
        this.props.dispatch(changePassword({password: this.state.newPassword}));
    }
    render () {
        const { syncedId, userId, currentIdIsAdmin, is_admin, initialized } = this.props;
        return (
            <form className="ui form">
                <div className="ui container">
                    <div className="ui padded very relaxed two column grid">
                        <div className="column ui"><div className="ui segment">


                            <div className="field required">
                                <label>
                                    Имя Фамилия
                                </label>

                                {currentIdIsAdmin ? <Field
                                    name="name"
                                    type="text"
                                    component="input"
                                /> : <div>{this.props.name}</div>}
                            </div>

                            {(currentIdIsAdmin || syncedId === userId) && 
                                (<div className="field required">
                                    <label>
                                        Адрес электронной почты
                                    </label>

                                    {currentIdIsAdmin
                                        ? <Field
                                            name="email"
                                            type="text"
                                            component="input"
                                        />
                                        : <div>{this.props.email}</div>}
                                </div>)
                            }


                            {syncedId == userId && <div className="field">
                                <label>
                                    Двухфакторная аутентификация
                                </label>

                                <Field
                                    name="otp_enabled"
                                    type="checkbox"
                                    component="input"
                                    onChange={(e) => this.toggle2faEnable(e)}
                                />
                                
                                {this.props.otp_enabled && <img src={this.props.qr_code} />}
                            </div>}
                            {currentIdIsAdmin
                              ? <div style={{display:'flex',flexDirection:'column',}}>
                                  <strong>Роли</strong>
                                  <div className="field ui checkbox">
                                    <Field
                                      name="is_admin"
                                      type="checkbox"
                                      component="input"
                                    />
                                    <label>Администратор</label>
                                  </div>
                                  <div className="field ui checkbox">
                                    <Field
                                      name="is_editor"
                                      type="checkbox"
                                      component="input"
                                    />
                                    <label>Редактор</label>
                                  </div>
                                  <div className="field ui checkbox">
                                    <Field name="active" type="checkbox" component="input" />
                                    <label>Пользователь активен</label>
                                  </div>
                                </div>
                              : <RoleTags
                                    isAdmin={is_admin}
                                    isEditor={this.props.is_editor}
                                    isActive={initialized}
                                    title="Роли"
                                />
                            }
                                <div className="ui divider"></div>

                            {currentIdIsAdmin
                            ? (
                                <button onClick={this.genPass} className="resetPassword ui basic red button">
                                    Сбросить пароль
                                </button>
                            ): null}
                            {syncedId == userId && !this.state.showPassword
                              ? (
                              <button onClick={() => this.showChangePassword()} className="showChangePassword ui basic red button">
                                  Сменить пароль
                              </button>
                            ): null}
                            { this.state.showPassword ? [
                              <div className="field ui">
                                  <label>
                                      Пароль
                                  </label>
                                  <ErrorLabel>{this.state.passError}</ErrorLabel>
                                  <input type="password" value={this.state.newPassword} style={{borderColor: this.state.isStrongPassword ? "green" : "red"}} onChange={(e) => this.changeNewPassword(e)}/>
                              </div>,
                              <div className="field ui">
                                  <label>
                                      Подтверждение пароля
                                  </label>
                                  <input type="password" value={this.state.passwordConfirm} onChange={(e) => this.changeConfirmPassword(e)}/>
                                  { this.state.newPassword != this.state.passwordConfirm && !this.state.newPasswordChange
                                    ? <p style={{color: 'red'}}>Не совпадают пароли</p>
                                    :null }
                              </div>,
                                <button
                                    disabled={this.state.newPassword != this.state.passwordConfirm || this.state.newPassword.length < 5 || !this.state.isStrongPassword}
                                    onClick={(e) => this.sendChangePassword(e)} className="resetPassword ui basic red button">
                                Сменить пароль
                                </button>,
                                this.props.changedPassword ? <p style={{color: 'green'}}>Пароль изменен</p> : null
                            ] : null}
                            { this.state.pass }
                        </div></div>
                        <div className="column">
                            <div className="ui segment">
                                {syncedId == userId &&
                                    (<div
                                        className="logout ui basic button"
                                        onClick={(e) => this.logout()}>
                                            Выйти из этой сессии
                                        </div>
                                    )
                                }
                                {(currentIdIsAdmin || syncedId == userId) && (<>
                                    {this.props.sessions && this.props.sessions.length > 0 &&
                                        <div
                                            className="logout ui basic button"
                                            onClick={this.props.logoutForeignUser}>
                                                Завершить все сессии
                                        </div>
                                    }
                                    <UserSessionList
                                        isLoading={this.props.sessionsLoading}
                                        sessionList={this.props.sessions}
                                        errorMessage={this.props.sessionsError}
                                    />
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );

    }
};