import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';

const FLUTTER_SUPPORT_FIELD = 'flutter__support';

const MobileAppSettings = ({ title, formName, disabled }) => {
  const formValues = useSelector((state) => getFormValues(formName)(state));

  const dispatch = useDispatch();

  const changeFlutterSupport = (value) => {
    if (typeof value === 'boolean') {
      dispatch(change(formName, FLUTTER_SUPPORT_FIELD, value));
    } else {
      console.error(
        'Mobile App Settings error:',
        `invalid value for ${FLUTTER_SUPPORT_FIELD} field.`,
        `Expected boolean, got ${typeof value}`,
      );
    }
  };

  return (
    <div className="ui segment">
      <div className="ui small dividing header">
        {title || 'Настройки мобильного приложения'}
      </div>

      <div className="ui toggle checkbox">
        <input
          type="checkbox"
          id={FLUTTER_SUPPORT_FIELD}
          name={FLUTTER_SUPPORT_FIELD}
          checked={formValues[FLUTTER_SUPPORT_FIELD]}
          onChange={(e) => changeFlutterSupport(e.target.checked)}
          disabled={disabled}
        />
        <label htmlFor={FLUTTER_SUPPORT_FIELD}>
          Будет открыт в мобильном приложении
        </label>
      </div>
    </div>
  );
};

export default MobileAppSettings;
