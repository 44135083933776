const types = require('./constants.js').types;
const moment = require('moment');
require('moment/locale/ru');

const E = {};

E.getType = function getType({ currentType, lang1, lang2 }) {
  const foundSection = types.find(obj => obj[lang1] === currentType);
  if (foundSection) {
    return foundSection[lang2];
  }
  return '';
};

E.urls = function urls({ date, slug, format }) {
  let url = '';
  if (slug[0] === '/') {
    return slug;
  }
  switch (format) {
    case 'page':
      url = `/${slug}`;
      break;
    case 'theme':
      url = `/theme/${slug}`;
      break;
    case 'chronicle':
      url = `/chronicle/${slug}`;
      break;
    case 'card':
      url = `/${format}/${slug}`;
      break;
    case 'podcast':
      url = `/${format}/${slug}`;
      break;
    default:
      url = `/${format}/` + moment(date).format('YYYY/MM/DD') + `/${slug}`;
      break;
  }
  return url.replace('//', '/');
};


E.relatedNames = {
  themes: 'Темы',
  categories: 'Разделы',
  context: 'Материалы',
};

module.exports = E;
