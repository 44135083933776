import generateId from '../utils/generateId';

export default {
  ui: {
    ckToolbar: false,
    isChanged: false,
    focus: {},
  },
  focusUi: {},
  user: null,
  authErr: '',
  listPosts: [],
  post: {},
  windowId: generateId(),
  collections: {},
};
