const languages = {
  ru: 'Русский',
  en: 'Английский',
  by: 'Беларусский',
  kz: 'Казахский',
  kg: 'Кыргызский',
  uz: 'Узбекский',
};

const options = Object
  .keys(languages)
  .map((key) => ({ label: languages[key], value: key }));

export { languages, options };
